<template>
  <div class="top">
    <div>
      <img src="../../assets/组 1.png" />
    </div>
    <div class="menu-container">
      <ul class="main-menu">
        <li v-for="(item, index) in menuItems" :key="index" @mouseenter="showSubmenu(index)" @mouseleave="hideSubmenu" @click.stop="handleParentClick(item)">
          {{ item.title }}
          <!-- 二级菜单 -->
          <ul v-if="item.children && activeIndex === index" class="submenu level-1">
            <li v-for="(subItem, subIndex) in item.children" :key="subIndex" @click.stop="handleChildClick(subItem)">
              {{ subItem.title }}
              <!-- 三级菜单 -->
              <ul v-if="subItem.children" class="submenu level-2">
                <li v-for="(thirdItem, thirdIndex) in subItem.children" :key="thirdIndex" @click.stop="handleChildClick(thirdItem)">
                  {{ thirdItem.title }}
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- 原菜单 -->
    <!-- <div class="category">
      <div class="child" @click="goToMain">首页</div>
      <div class="child" @click="goToProduct">产品与服务</div>
      <div class="child" @click="goToSolution">行业解决方案</div>
      <div class="child" @click="goToSuccessCase">成功案例</div>
      <div class="child-link" 
        v-for="(item, index) in tabList"
        :key="index"
        @mouseover="
          item.name == '平台链接'
            ? (item.isVisibleMenuBox = true)
            : (item.isVisibleMenuBox = false)
        "
        @mouseleave="
          item.isVisibleMenuBox = false;
          isMenuHover = -1;
        ">
        <span>{{ item.name }}</span>
        <div class="menu" v-show="item.isVisibleMenuBox">
          <div class="menu-box">
            <div class="menu-box-cascader">
              <div
                class="menu-item"
                v-for="(menuItem, menuIndex) in menuList"
                :key="menuIndex"
                :class="{ 'menu-hover': menuIndex == isMenuHover }"
                @mouseover="handleMenuMouseOver(menuItem, menuIndex)"
                @mouseleave="handleMenuMouseLeave()"
                @click="handleMenuClick(menuItem)"
              >
                <span @click.prevent>{{ menuItem.label }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="child" @click="goToContact">联系我们</div>
    </div> -->
  </div> 
  
</template>

<script>
export default {
  name: 'Top',
  data () {
    return {
      tabList: [
        {
          name: '平台链接',
          isVisibleMenuBox: false,
        },
      ],
      menuList: [
        {
          label: '摩鸣数字化办公平台',
          link: 'http://mmkj.mominger.com:9091/'
        },
        {
          label: '摩鸣综合资源管理平台',
          link: 'http://mmkj.mominger.com:68/#/'
        },
        {
          label: '摩鸣模型素材展示平台',
          link: 'http://mmkj.mominger.com:75/#/'
        },
        {
          label: 'AI平台（外网）',
          link: 'http://mmkj.mominger.com:7070'
        },
        {
          label: 'AI平台（内网）',
          link: 'http://192.168.3.72:8080'
        },
      ],
      // 菜单索引:高亮
      isMenuHover: -1,
      isVisibleSubMenuBox: false,
      // 新菜单变量20250213
      activeIndex: -1,
      menuItems: [
        { title: "首页", link: "http://www.mominger.com/" },
        { title: "产品与服务", link: "http://mmkj.mominger.com:78/" },
        { title: "行业解决方案", link: "http://mmkj.mominger.com:78/solution" },
        { title: "成功案例", link: "http://mmkj.mominger.com:78/successCase" },
        {
          title: "平台链接",
          children: [
            // { title: "摩鸣数字化办公平台", link: "http://mmkj.mominger.com:9091/" },
            { title: "摩鸣综合资源管理平台", link: "http://mmkj.mominger.com:68/#/" },
            { title: "摩鸣模型素材展示平台", link: "http://mmkj.mominger.com:75/#/" },
            {
              title: "AI平台",
              children: [
                { title: "内网", link: "http://192.168.3.72:3000" },
                { title: "外网", link: "http://mmkj.mominger.com:3060" }
              ]
            }
          ]
        },
        { title: "联系我们", link: "http://mmkj.mominger.com:78/contact" },
        { title: "人才招聘", link: "http://mmkj.mominger.com:78/jobs" }
      ]
    }
  },
  methods: {
    goToMain() {
      window.location.href = "http://www.mominger.com/"
    },
    goToProduct() {
      window.location.href = "http://mmkj.mominger.com:78/"
    },
    goToSuccessCase() {
      window.location.href = "http://mmkj.mominger.com:78/successCase"
    },
    goToSolution() {
      window.location.href = "http://mmkj.mominger.com:78/solution"
    },
    goToContact() {
      window.location.href = "http://mmkj.mominger.com:78/contact"
    },
    handleMenuMouseOver(menuItem, menuIndex) {
      this.isMenuHover = menuIndex;
    },
    handleMenuMouseLeave() {
      this.isMenuHover = -1;
    },
    handleMenuClick(menuItem)  {
      window.open(menuItem.link, '_blank');
    },
    // 新菜单方法20250213
    showSubmenu(index) {
      this.activeIndex = index;
    },
    hideSubmenu() {
      this.activeIndex = -1;
    },
    handleParentClick(item) {
      if (!item.children) {
        window.location.href = item.link;
      }
    },
    handleChildClick(item) {
      if (!item.children) {
        window.open(item.link, '_blank');
      }
    }
  },
}
</script>

<style lang="less" scoped>
.top {
  height: 120px;
  width: 100%;  
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .category {
    // width: 485px;
    width: 545px;
    display: flex;
    justify-content: space-between;
    color: #060606;
    font-size: 16px;
    .child {
      cursor: pointer;
    }
    .child-link {
      cursor: pointer;
      .menu {
        position: absolute;
        top: 71px;
        right: 2px;
        z-index: 10;
        width: 240px;
        height: auto;
        padding-top: 10px;
        .menu-box {
          position: relative;
          display: flex;
          width: 100%;
          height: auto;
          .menu-box-cascader {
            width: 100%;
            height: auto;
            border-radius: 5px;
            box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.15);
            display: block;
            // &:nth-of-type(2) {
            //   position: absolute;
            //   top: 0;
            //   left: 152px;
            //   width: 180px;
            //   // background-color: #faf;
            // }
 
            .menu-item {
              position: relative;
              width: 100%;
              height: 48px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              background-color: #ECECEC;
              border-radius: 3px;
              span {
                font-size: 16px;
                color: #060606;
                height: 16px;
                font-family: MicrosoftYaHei;
                line-height: 17px;
              }
 
              // .el-icon-arrow-right {
              //   font-size: 12px;
              //   position: absolute;
              //   right: 2px;
              // }
 
              &:hover {
                background-color: #0068F7;
 
                span, .el-icon-arrow-right {
                  color: #ffffff;
                }
              }
            }
 
            .menu-hover {
              background-color: #0068F7;
 
              span, .el-icon-arrow-right {
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .child:hover {
      color: #5EB4E6;
    }
    .child-link:hover {
      color: #5EB4E6;
    }
  }
}
// .top::before {  
// }
.dropdown {
  .dropdown-title {
    display: inline-block;
    position: relative;
    cursor: pointer;
    .arrow {
      width: 8px;
      height: 8px;
      margin-left: 5px;
    }
    .arrow:hover {
      color: #5EB4E6;
    }
  }
  .dropdown-content {
    // 定位显示局域
    position: absolute;
    visibility: hidden; // 隐藏
    opacity: 0; // 隐藏
    transition: all 0.6s ease-in-out;
    background-color: #FFFFFF;
    width: 110px;
    .dropdown-menu {
      margin-top: 4px;  // 与title制造距离
      padding: 15px 0px 0px 10px; // 给下面多留一点距离，抵消视觉差
      color: white;
      border-radius: 4px;
      .menuItem {
        width: 100%;  // 自适应宽度
        white-space: nowrap;
        padding: 10px 10px;
        font-size: 14px;
        color: #060606;
        cursor: pointer;
        border-radius: 4px;
        text-align: left;
        &:hover {
          color: #5EB4E6;
        }
      }
    }
  }
  &:hover .dropdown-content {
    visibility: visible;
    opacity: 1;
  }
}
// 新菜单样式20250213
.menu-container {
  font-family: Arial, sans-serif;
  color: #060606;
}

.main-menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-menu > li {
  position: relative;
  padding: 15px 15px;
  cursor: pointer;
  transition: background 0.3s;
  transition: all 0.3s ease; /* 添加过渡动画 */
}

.main-menu > li:hover {
  color: #5EB4E6;
  transform: translateY(-2px); /* 添加轻微上移效果 */
}

.submenu {
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  background: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  /* min-width: 200px; */
  display: none;
  text-align: center;
}

.main-menu > li:hover .submenu {
  display: block;
}

.submenu.level-1 {
  top: 100%;
  left: 0;
  min-width: 200px;
  color: #060606; /* 子菜单保持原有颜色 */
}

.submenu.level-1 > li {
  position: relative;
  padding: 10px 20px;
  transition: all 0.3s ease; /* 添加过渡动画 */
}

.submenu.level-1 > li:hover {
  color: #5EB4E6;
  background: #f8f8f8;
}

.submenu.level-2 {
  top: 0;
  left: 100%;
  display: none !important;
  min-width: 100px;
  color: #060606;
}

.submenu.level-2 > li {
  position: relative;
  padding: 5px 20px;
  transition: all 0.3s ease; /* 添加过渡动画 */
}

.submenu.level-2 > li:hover {
  color: #5EB4E6;
  background: #f8f8f8;
}

.submenu.level-1 > li:hover .submenu.level-2 {
  display: block !important;
}
/* 响应式调整 */
@media (max-width: 1200px) {
  .submenu.level-2 {
    left: auto;
    right: 100%; /* 改为从右侧展开 */
    box-shadow: -2px 2px 5px rgba(0,0,0,0.2); /* 调整阴影方向 */
    margin-right: 2px;
  }

  /* 修正三级菜单位置 */
  // .submenu.level-1 > li:hover .submenu.level-2 {
  //   transform: translateX(-5px);
  // }
}
</style>
